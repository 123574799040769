import React, { useState, useEffect } from 'react';
import { ref, push, get, serverTimestamp } from 'firebase/database';
import { auth, db } from '../firebase';
import Select from 'react-select';
import { AlertCircle, Save } from 'lucide-react';

const STOCK_OUT_REASONS = [
  { value: 'no_delivery', label: 'No Delivery' },
  { value: 'late_delivery', label: 'Late Delivery' },
  { value: 'high_demand', label: 'High Demand' },
  { value: 'supply_chain', label: 'Supply Chain Issues' },
  { value: 'damaged_stock', label: 'Damaged Stock' },
  { value: 'expired_stock', label: 'Expired Stock' },
  { value: 'ordering_issue', label: 'Ordering Issue' },
  { value: 'other', label: 'Other' }
];

const AddOutOfStock = () => {
  // State Management
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [otherReason, setOtherReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesRef = ref(db, 'stores');
        const categoriesRef = ref(db, 'categories');
        const productsRef = ref(db, 'products');

        const [storesSnap, categoriesSnap, productsSnap] = await Promise.all([
          get(storesRef),
          get(categoriesRef),
          get(productsRef)
        ]);

        if (storesSnap.exists()) {
          const storesData = Object.entries(storesSnap.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            location: store.searchLocation,
            parish: store.parish,
            channel: store.channel
          }));
          setStores(storesData);
        }

        if (categoriesSnap.exists()) {
          const categoriesData = Object.entries(categoriesSnap.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        if (productsSnap.exists()) {
          const productsData = Object.entries(productsSnap.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            sku: product.productNumber,
            competitorProductName: product.competitorProductName
          }));
          setProducts(productsData);
        }
      } catch (error) {
        setError('Error loading data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter products when category is selected
  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(
        product => product.categoryId === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
  }, [selectedCategory, products]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedStore || !selectedCategory || !selectedProduct || !selectedReason) {
      setError('Please fill in all required fields');
      return;
    }

    if (selectedReason.value === 'other' && !otherReason.trim()) {
      setError('Please specify the other reason');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const outOfStockRef = ref(db, 'outOfStock');
      const newOutOfStock = {
        storeId: selectedStore.value,
        storeName: selectedStore.label,
        storeLocation: selectedStore.location,
        categoryId: selectedCategory.value,
        categoryName: selectedCategory.label,
        productId: selectedProduct.value,
        productName: selectedProduct.label,
        sku: selectedProduct.sku,
        reason: selectedReason.value === 'other' ? otherReason : selectedReason.label,
        reasonCode: selectedReason.value,
        timestamp: serverTimestamp(),
        uploadedBy: auth.currentUser.uid
      };

      await push(outOfStockRef, newOutOfStock);
      
      // Reset form
      setSelectedStore(null);
      setSelectedCategory(null);
      setSelectedProduct(null);
      setSelectedReason(null);
      setOtherReason('');
      setSuccess('Out of stock report saved successfully');
      
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      setError('Error saving report: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !selectedStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Report Out of Stock</h1>

        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
              <p className="text-green-700">{success}</p>
            </div>
          )}

          <div className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store *
              </label>
              <Select
                options={stores}
                value={selectedStore}
                onChange={setSelectedStore}
                placeholder="Select store..."
                isDisabled={loading}
              />
            </div>

            {/* Store Details */}
            {selectedStore && (
              <div className="bg-gray-50 rounded p-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-medium">Location:</span> {selectedStore.location}
                  </div>
                  <div>
                    <span className="font-medium">Parish:</span> {selectedStore.parish}
                  </div>
                  <div>
                    <span className="font-medium">Channel:</span> {selectedStore.channel}
                  </div>
                </div>
              </div>
            )}

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category *
              </label>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={setSelectedCategory}
                placeholder="Select category..."
                isDisabled={loading}
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product *
              </label>
              <Select
                options={filteredProducts}
                value={selectedProduct}
                onChange={setSelectedProduct}
                placeholder="Select product..."
                isDisabled={!selectedCategory || loading}
                formatOptionLabel={({ label, competitorProductName, sku }) => (
                  <div>
                    <div>{label}</div>
                    {competitorProductName && (
                      <div className="text-sm text-gray-500">
                        {competitorProductName}
                      </div>
                    )}
                    <div className="text-xs text-gray-400">
                      SKU: {sku}
                    </div>
                  </div>
                )}
              />
            </div>

            {/* SKU Display */}
            {selectedProduct && (
              <div className="bg-gray-50 rounded p-4">
                <div className="text-sm">
                  <span className="font-medium">SKU:</span> {selectedProduct.sku}
                </div>
              </div>
            )}

            {/* Reason Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Reason *
              </label>
              <Select
                options={STOCK_OUT_REASONS}
                value={selectedReason}
                onChange={setSelectedReason}
                placeholder="Select reason..."
                isDisabled={loading}
              />
            </div>

            {/* Other Reason Input */}
            {selectedReason?.value === 'other' && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Specify Reason *
                </label>
                <textarea
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  rows={3}
                  placeholder="Please specify the reason for out of stock..."
                />
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="mr-2 h-4 w-4" />
              {loading ? 'Saving...' : 'Save Report'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOutOfStock;