import React, { useState, useEffect, useCallback } from 'react';
import { ref, getDatabase, query, onValue } from 'firebase/database';
import { auth } from '../firebase';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Activity = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [error, setError] = useState(null);

  const getActivityDetails = (type, data) => {
    switch (type) {
      case 'Timesheet':
        return `${data.status === 'completed' ? 'Completed' : 'Started'} timesheet at ${data.storeName}`;
      case 'Stock Count':
        return `Recorded stock count for SKU ${data.sku}`;
      case 'Out of Stock':
        return `Reported out of stock for ${data.productName || data.sku}`;
      case 'Price Audit':
        return `Conducted price audit for ${data.productName || 'product'}`;
      case 'Planogram':
        return `Updated planogram for ${data.productName || 'product'} - ${data.compliance}`;
      case 'Product Expiry':
        return `Recorded ${data.status} product - Action: ${data.action}`;
      case 'Marketing Activity':
        return `Recorded ${data.promotionType} activity`;
      case 'Opportunity/Threat':
        return `Recorded ${data.type}`;
      default:
        return 'Activity recorded';
    }
  };

  const sortActivities = (items, order) => {
    return [...items].sort((a, b) => {
      return order === 'desc' 
        ? b.timestamp - a.timestamp 
        : a.timestamp - b.timestamp;
    });
  };

  const loadActivities = useCallback(async () => {
    if (!auth.currentUser) return;

    const db = getDatabase();
    const userId = auth.currentUser.uid;
    const newActivities = [];
    const unsubscribers = [];

    try {
      const dataTypes = [
        { path: 'timesheets', field: 'userId', type: 'Timesheet', timeField: 'checkInTime' },
        { path: 'stock', field: 'uploadedBy', type: 'Stock Count', timeField: 'timestamp' },
        { path: 'outOfStock', field: 'uploadedBy', type: 'Out of Stock', timeField: 'timestamp' },
        { path: 'priceAudits', field: 'uploadedBy', type: 'Price Audit', timeField: 'timestamp' },
        { path: 'planograms', field: 'uploadedBy', type: 'Planogram', timeField: 'timestamp' },
        { path: 'productExpiry', field: 'userId', type: 'Product Expiry', timeField: 'timestamp' },
        { path: 'marketingActivities', field: 'userId', type: 'Marketing Activity', timeField: 'timestamp' },
        { path: 'opportunities', field: 'userId', type: 'Opportunity/Threat', timeField: 'timestamp' }
      ];

      dataTypes.forEach(({ path, field, type, timeField }) => {
        const dbRef = query(ref(db, path));
        const unsubscribe = onValue(dbRef, (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
              const data = childSnapshot.val();
              if (data[field] === userId) {
                newActivities.push({
                  id: childSnapshot.key,
                  path,
                  type,
                  timestamp: data[timeField],
                  details: getActivityDetails(type, data),
                  store: data.storeName,
                  location: data.storeLocation || data.location,
                  data: data
                });
              }
            });
          }
          
          const sortedActivities = sortActivities(newActivities, sortOrder);
          setActivities(sortedActivities);
          setFilteredActivities(sortedActivities);
          setLoading(false);
        }, (error) => {
          setError(`Error loading activities: ${error.message}`);
          setLoading(false);
        });
        
        unsubscribers.push(unsubscribe);
      });
    } catch (error) {
      setError(`Error loading activities: ${error.message}`);
      setLoading(false);
    }

    return () => unsubscribers.forEach(unsubscribe => unsubscribe());
  }, [sortOrder]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        loadActivities();
      } else {
        setActivities([]);
        setFilteredActivities([]);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [loadActivities]);

  useEffect(() => {
    if (!activities.length) return;
    
    if (startDate && endDate) {
      const filtered = activities.filter(activity => {
        const activityDate = new Date(activity.timestamp);
        return activityDate >= startDate && activityDate <= endDate;
      });
      setFilteredActivities(filtered);
    } else {
      setFilteredActivities(activities);
    }
  }, [dateRange, activities, startDate, endDate]);

  const handleSort = () => {
    const newOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    setSortOrder(newOrder);
    const sorted = sortActivities(filteredActivities, newOrder);
    setFilteredActivities(sorted);
  };

  const getActivityTypeColor = (type) => {
    const colors = {
      'Timesheet': 'bg-blue-100 text-blue-800',
      'Stock Count': 'bg-green-100 text-green-800',
      'Out of Stock': 'bg-red-100 text-red-800',
      'Price Audit': 'bg-purple-100 text-purple-800',
      'Planogram': 'bg-yellow-100 text-yellow-800',
      'Product Expiry': 'bg-orange-100 text-orange-800',
      'Marketing Activity': 'bg-pink-100 text-pink-800',
      'Opportunity/Threat': 'bg-indigo-100 text-indigo-800'
    };
    return colors[type] || 'bg-gray-100 text-gray-800';
  };

  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => {
    const displayValue = startDate && endDate 
      ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
      : "Select Date Range";
    return (
      <button 
        className="px-4 py-2 text-left bg-white border rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
        onClick={onClick} 
        ref={ref}
      >
        {displayValue}
      </button>
    );
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Activity Log</h1>

          {error && (
            <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <div className="flex justify-between items-center mb-6">
            {/* Date Range Filter */}
            <div className="flex-1 mr-4">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={<CustomDateInput />}
              />
            </div>

            {/* Sort Button */}
            <button
              onClick={handleSort}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Sort {sortOrder === 'desc' ? '↓' : '↑'}
            </button>
          </div>

          {/* Activities List */}
          <div className="space-y-4">
            {filteredActivities.length === 0 ? (
              <div className="text-center py-8 text-gray-500">
                No activities found for the selected period
              </div>
            ) : (
              filteredActivities.map((activity) => (
                <div key={activity.id} className="border rounded-lg p-4 hover:bg-gray-50">
                  <div className="flex items-start justify-between">
                    <div className="flex-1">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getActivityTypeColor(activity.type)}`}>
                        {activity.type}
                      </span>
                      <p className="mt-2 text-sm text-gray-900">{activity.details}</p>
                      <div className="mt-1 text-xs text-gray-500">
                        {activity.store && activity.location && (
                          <span>{activity.store} - {activity.location} • </span>
                        )}
                        {new Date(activity.timestamp).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;