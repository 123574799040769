import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { ref, push, get, getDatabase } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, auth } from '../firebase';

const AddMarketingActivity = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    store: null,
    location: null,
    category: null,
    product: null,
    promotionType: null,
    description: '',
    photo: null
  });

  // State for dropdown options
  const [stores, setStores] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [error, setError] = useState('');

  const promotionTypes = [
    { value: 'Company Product', label: 'Company Product' },
    { value: 'Competitor Product', label: 'Competitor Product' }
  ];

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      const db = getDatabase();
      try {
        // Load stores
        const storesSnapshot = await get(ref(db, 'stores'));
        if (storesSnapshot.exists()) {
          const storesData = Object.entries(storesSnapshot.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            parish: store.parish,
            searchLocation: store.searchLocation
          }));
          setStores(storesData);
        }

        // Load categories
        const categoriesSnapshot = await get(ref(db, 'categories'));
        if (categoriesSnapshot.exists()) {
          const categoriesData = Object.entries(categoriesSnapshot.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        // Load products
        const productsSnapshot = await get(ref(db, 'products'));
        if (productsSnapshot.exists()) {
          const productsData = Object.entries(productsSnapshot.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            productNumber: product.productNumber,
            competitorProductName: product.competitorProductName
          }));
          setProducts(productsData);
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Error loading data. Please try again.');
      }
    };

    loadData();
  }, []);

  // Update locations when store changes
  useEffect(() => {
    if (formData.store) {
      const store = stores.find(s => s.value === formData.store.value);
      if (store) {
        // Create location option from the store's searchLocation
        const locationOption = {
          value: store.searchLocation,
          label: store.searchLocation
        };
        setLocations([locationOption]);
        // Automatically set the location since there's only one option
        setFormData(prev => ({
          ...prev,
          location: locationOption
        }));
      }
    } else {
      setLocations([]);
      setFormData(prev => ({
        ...prev,
        location: null
      }));
    }
  }, [formData.store, stores]);

  // Update filtered products when category changes
  useEffect(() => {
    if (formData.category) {
      const categoryProducts = products.filter(
        product => product.categoryId === formData.category.value
      );
      setFilteredProducts(categoryProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [formData.category, products]);

  // Handle form input changes
  const handleChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Reset dependent fields
    if (name === 'store') {
      setFormData(prev => ({
        ...prev,
        location: null
      }));
    }
    if (name === 'category') {
      setFormData(prev => ({
        ...prev,
        product: null
      }));
    }
  };

  // Handle photo upload
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        alert('Photo must be less than 5MB');
        return;
      }

      setFormData(prev => ({
        ...prev,
        photo: file
      }));

      // Create preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (!auth.currentUser) {
        throw new Error('No authenticated user found');
      }

      // Validate required fields
      if (!formData.store || !formData.location || !formData.category || !formData.product || 
          !formData.promotionType || !formData.description || !formData.photo) {
        throw new Error('Please fill in all required fields');
      }

      // Upload photo with correct path structure
      const imageId = `${Date.now()}_${formData.photo.name}`;
      const photoPath = `marketing_activity_images/${auth.currentUser.uid}/${imageId}`;
      const photoStorageRef = storageRef(storage, photoPath);
      
      // Add metadata to ensure content type is set
      const metadata = {
        contentType: formData.photo.type,
      };
      
      await uploadBytes(photoStorageRef, formData.photo, metadata);
      const photoUrl = await getDownloadURL(photoStorageRef);

      // Rest of the submission logic remains the same
      const activityData = {
        storeId: formData.store.value,
        location: formData.location.value,
        categoryId: formData.category.value,
        productId: formData.product.value,
        productNumber: formData.product.productNumber,
        promotionType: formData.promotionType.value,
        description: formData.description,
        photoUrl,
        userId: auth.currentUser.uid,
        timestamp: Math.floor(Date.now() / 1000)
      };

      // Save to database
      const db = getDatabase();
      await push(ref(db, 'marketingActivities'), activityData);

      alert('Marketing activity added successfully');
      navigate('/marketing-activities');

    } catch (error) {
      console.error('Error adding marketing activity:', error);
      if (error.code === 'storage/unauthorized') {
        setError('Permission denied: Unable to upload photo. Please check your permissions.');
      } else {
        setError(error.message || 'Error adding marketing activity');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Add Marketing Activity</h1>
          
          {error && (
            <div className="mb-4 p-4 text-red-700 bg-red-100 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store*
              </label>
              <Select
                value={formData.store}
                onChange={(value) => handleChange('store', value)}
                options={stores}
                isSearchable
                placeholder="Select Store"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Location Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Location*
              </label>
              <Select
                value={formData.location}
                onChange={(value) => handleChange('location', value)}
                options={locations}
                isSearchable
                placeholder="Select Location"
                isDisabled={!formData.store}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category*
              </label>
              <Select
                value={formData.category}
                onChange={(value) => handleChange('category', value)}
                options={categories}
                isSearchable
                placeholder="Select Category"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product*
              </label>
              <Select
                value={formData.product}
                onChange={(value) => handleChange('product', value)}
                options={filteredProducts}
                isSearchable
                placeholder="Select Product"
                isDisabled={!formData.category}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Promotion Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Type of Promotion*
              </label>
              <Select
                value={formData.promotionType}
                onChange={(value) => handleChange('promotionType', value)}
                options={promotionTypes}
                placeholder="Select Promotion Type"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Description */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Description*
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => handleChange('description', e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                rows="4"
                placeholder="Enter activity description"
              />
            </div>

            {/* Photo Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Photo*
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
              {photoPreview && (
                <div className="mt-2">
                  <img
                    src={photoPreview}
                    alt="Preview"
                    className="w-32 h-32 object-cover rounded-lg"
                  />
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/marketing-activities')}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-300 disabled:cursor-not-allowed"
              >
                {loading ? 'Saving...' : 'Save Activity'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMarketingActivity;