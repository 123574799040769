import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Sidebar from './components/SideBar';
import AddTimeSheet from './components/AddTimeSheet';
import AddStockCount from './components/AddStockCount';
import Login from './components/Login';
import Register from './components/Register';
import AddOutOfStock from './components/AddOutofStock';
import AddPriceAudit from './components/AddPriceAudit';
import AddPlanogram from './components/AddPlanogram';
import AddProductExpiryDamage from './components/AddProductExpiryDamage';
import AddMarketingActivity from './components/AddMarketingActivity';
import AddOpportunityThreat from './components/AddOpportunityThreat';
import Activity from './components/Activity';

// Checklist component
const Checklist = () => <div>Daily Checklist Page</div>;

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
  </div>
);

// Public Route component (prevents authenticated users from accessing login/register)
const PublicRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return <LoadingSpinner />;
  }

  if (isAuthenticated) {
    return <Navigate to="/add-timesheet" />;
  }

  return children;
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  const [authChecked, setAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userName, setUserName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserName(user.displayName || 'Merchandiser');
        setUserRole('User');
        setIsAuthenticated(true);
      } else {
        setUserName('');
        setUserRole('');
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <div className="flex h-screen bg-gray-100">
        {isAuthenticated && (
          <Sidebar
            isOpen={sidebarOpen}
            toggleSidebar={toggleSidebar}
            userRole={userRole}
            userName={userName}
          />
        )}
        
        <div className="flex-1 flex flex-col overflow-hidden">
          {isAuthenticated && (
            <header className="block lg:hidden bg-white shadow">
              <div className="flex items-center justify-between p-4">
                <button
                  onClick={toggleSidebar}
                  className="text-gray-500 focus:outline-none focus:text-gray-600"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
                <h1 className="text-lg font-semibold">Merchandiser Portal</h1>
              </div>
            </header>
          )}

          <main className={`flex-1 overflow-x-hidden overflow-y-auto ${isAuthenticated ? 'bg-gray-100' : 'bg-white'}`}>
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
              <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />

              {/* Protected Routes */}
              <Route path="/" element={<ProtectedRoute><Navigate to="/add-timesheet" replace /></ProtectedRoute>} />
              <Route path="/add-timesheet" element={<ProtectedRoute><AddTimeSheet /></ProtectedRoute>} />
              <Route path="/stock-count" element={<ProtectedRoute><AddStockCount /></ProtectedRoute>} />
              <Route path="/out-of-stock" element={<ProtectedRoute><AddOutOfStock /></ProtectedRoute>} />
              <Route path="/price-audit" element={<ProtectedRoute><AddPriceAudit /></ProtectedRoute>} />
              <Route path="/planogram-compliance" element={<ProtectedRoute><AddPlanogram /></ProtectedRoute>} />
              <Route path="/product-expiry-damage" element={<ProtectedRoute><AddProductExpiryDamage /></ProtectedRoute>} />
              <Route path="/add-marketing-activity" element={<ProtectedRoute><AddMarketingActivity /></ProtectedRoute>} />
              <Route path="/opportunity-threat" element={<ProtectedRoute><AddOpportunityThreat /></ProtectedRoute>} />
              <Route path="/activity" element={<ProtectedRoute><Activity /></ProtectedRoute>} />
              <Route path="/checklist" element={<ProtectedRoute><Checklist /></ProtectedRoute>} />

              {/* Catch all route */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
