import React, { useState, useEffect } from 'react';
import { ref as dbRef, push, get } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../firebase';
import Select from 'react-select';
import { AlertCircle, Save, Upload } from 'lucide-react';

const COMPLIANCE_OPTIONS = [
  { value: 'Compliant', label: 'Compliant' },
  { value: 'Non-Compliant', label: 'Non-Compliant' },
  { value: 'Partially Compliant', label: 'Partially Compliant' }
];

const ComplianceBadge = ({ status }) => {
  const statusClasses = {
    'Compliant': "bg-green-100 text-green-800",
    'Non-Compliant': "bg-red-100 text-red-800",
    'Partially Compliant': "bg-yellow-100 text-yellow-800"
  };

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-medium ${statusClasses[status]}`}>
      {status}
    </span>
  );
};

const AddPlanogram = () => {
  // State Management
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCompliance, setSelectedCompliance] = useState(null);
  const [action, setAction] = useState('');
  const [beforePhoto, setBeforePhoto] = useState(null);
  const [afterPhoto, setAfterPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // Preview states
  const [beforePhotoPreview, setBeforePhotoPreview] = useState(null);
  const [afterPhotoPreview, setAfterPhotoPreview] = useState(null);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesRef = dbRef(db, 'stores');
        const categoriesRef = dbRef(db, 'categories');
        const productsRef = dbRef(db, 'products');

        const [storesSnap, categoriesSnap, productsSnap] = await Promise.all([
          get(storesRef),
          get(categoriesRef),
          get(productsRef)
        ]);

        if (storesSnap.exists()) {
          const storesData = Object.entries(storesSnap.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            location: store.searchLocation,
            parish: store.parish,
            channel: store.channel
          }));
          setStores(storesData);
        }

        if (categoriesSnap.exists()) {
          const categoriesData = Object.entries(categoriesSnap.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        if (productsSnap.exists()) {
          const productsData = Object.entries(productsSnap.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            sku: product.productNumber,
            competitorProductName: product.competitorProductName
          }));
          setProducts(productsData);
        }
      } catch (error) {
        setError('Error loading data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter products when category is selected
  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(
        product => product.categoryId === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
  }, [selectedCategory, products]);

  // Handle image selection
  const handleImageChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError(`${type === 'before' ? 'Before' : 'After'} photo exceeds 5MB limit`);
        return;
      }
      
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === 'before') {
          setBeforePhoto(file);
          setBeforePhotoPreview(reader.result);
        } else {
          setAfterPhoto(file);
          setAfterPhotoPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file, type) => {
    if (!file) return null;
    try {
      const userId = auth.currentUser.uid;
      const fileName = `planogram_${type}_${Date.now()}_${file.name}`;
      const imageRef = storageRef(storage, `planogram_images/${userId}/${fileName}`);
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      throw new Error(`Error uploading ${type} image: ${error.message}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedStore || !selectedCategory || !selectedProduct || !selectedCompliance) {
      setError('Please fill in all required fields');
      return;
    }

    if (!beforePhoto || !afterPhoto) {
      setError('Please upload both before and after photos');
      return;
    }

    setLoading(true);
    setError('');
    try {
      // Upload images
      const [beforePhotoUrl, afterPhotoUrl] = await Promise.all([
        uploadImage(beforePhoto, 'before'),
        uploadImage(afterPhoto, 'after')
      ]);

      const planogramRef = dbRef(db, 'planograms');
      const currentTimestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
      
      const newPlanogram = {
        storeId: selectedStore.value,
        storeName: selectedStore.label,
        storeLocation: selectedStore.location,
        categoryId: selectedCategory.value,
        categoryName: selectedCategory.label,
        productId: selectedProduct.value,
        productName: selectedProduct.label,
        productNumber: selectedProduct.sku,
        compliance: selectedCompliance.value,
        action,
        beforePhotoUrl,
        afterPhotoUrl,
        timestamp: currentTimestamp,
        uploadedBy: auth.currentUser.uid
      };

      await push(planogramRef, newPlanogram);
      
      // Reset form
      setSelectedStore(null);
      setSelectedCategory(null);
      setSelectedProduct(null);
      setSelectedCompliance(null);
      setAction('');
      setBeforePhoto(null);
      setAfterPhoto(null);
      setBeforePhotoPreview(null);
      setAfterPhotoPreview(null);
      setSuccess('Planogram compliance report saved successfully');
      
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      setError('Error saving planogram report: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !selectedStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Add Planogram Compliance</h1>

        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
              <p className="text-green-700">{success}</p>
            </div>
          )}

          <div className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store *
              </label>
              <Select
                options={stores}
                value={selectedStore}
                onChange={setSelectedStore}
                placeholder="Select store..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Store Details */}
            {selectedStore && (
              <div className="bg-gray-50 rounded p-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-medium">Location:</span> {selectedStore.location}
                  </div>
                  <div>
                    <span className="font-medium">Parish:</span> {selectedStore.parish}
                  </div>
                  <div>
                    <span className="font-medium">Channel:</span> {selectedStore.channel}
                  </div>
                </div>
              </div>
            )}

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category *
              </label>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={setSelectedCategory}
                placeholder="Select category..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product *
              </label>
              <Select
                options={filteredProducts}
                value={selectedProduct}
                onChange={setSelectedProduct}
                placeholder="Select product..."
                isDisabled={!selectedCategory || loading}
                formatOptionLabel={({ label, competitorProductName, sku }) => (
                  <div>
                    <div>{label}</div>
                    {competitorProductName && (
                      <div className="text-sm text-gray-500">
                        {competitorProductName}
                      </div>
                    )}
                    <div className="text-xs text-gray-400">
                      SKU: {sku}
                    </div>
                  </div>
                )}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Compliance Status */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Compliance Status *
              </label>
              <Select
                options={COMPLIANCE_OPTIONS}
                value={selectedCompliance}
                onChange={setSelectedCompliance}
                placeholder="Select compliance status..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              {selectedCompliance && (
                <div className="mt-2">
                  <ComplianceBadge status={selectedCompliance.value} />
                </div>
              )}
            </div>

            {/* Action Taken */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Action Taken *
              </label>
              <textarea
                value={action}
                onChange={(e) => setAction(e.target.value)}
                rows={3}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Describe the action taken..."
                disabled={loading}
              />
            </div>

            {/* Photo Upload Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Before Photo */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Before Photo *
                </label>
                <div className="space-y-2">
                  <div className="flex items-center justify-center w-full">
                    <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-gray-500 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:text-gray-600 hover:border-gray-400">
                      <Upload className="h-12 w-12 mb-2" />
                      <span className="text-sm">Upload before photo</span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, 'before')}
                        disabled={loading}
                      />
                    </label>
                  </div>
                  {beforePhotoPreview && (
                    <div className="relative mt-2">
                      <img
                        src={beforePhotoPreview}
                        alt="Before preview"
                        className="w-full h-48 object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setBeforePhoto(null);
                          setBeforePhotoPreview(null);
                        }}
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                      >
                        ✕
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* After Photo */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  After Photo *
                </label>
                <div className="space-y-2">
                  <div className="flex items-center justify-center w-full">
                    <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-gray-500 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:text-gray-600 hover:border-gray-400">
                      <Upload className="h-12 w-12 mb-2" />
                      <span className="text-sm">Upload after photo</span>
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, 'after')}
                        disabled={loading}
                      />
                    </label>
                  </div>
                  {afterPhotoPreview && (
                    <div className="relative mt-2">
                      <img
                        src={afterPhotoPreview}
                        alt="After preview"
                        className="w-full h-48 object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setAfterPhoto(null);
                          setAfterPhotoPreview(null);
                        }}
                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600"
                      >
                        ✕
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="mr-2 h-4 w-4" />
              {loading ? 'Saving...' : 'Save Planogram Report'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPlanogram;