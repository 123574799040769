import React, { useState, useEffect } from 'react';
import { ref, push, serverTimestamp, set, get } from 'firebase/database';
import { ref as storageRef, uploadString, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase';
import Select from 'react-select';
import { Clock, MapPin, AlertCircle, Timer } from 'lucide-react';
import SignaturePad from 'react-signature-canvas';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '300px'
};

// Separate Map component
const LocationMap = React.memo(({ location }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyANPVJEOIz2vll2lYc-4vhcqsqzq6JlxRQ",
    version: "weekly"
  });

  if (loadError) {
    return (
      <div className="h-[300px] flex items-center justify-center bg-red-50 rounded">
        <div className="flex items-center text-red-500">
          <AlertCircle className="h-5 w-5 mr-2" />
          Error loading map
        </div>
      </div>
    );
  }

  if (!isLoaded || !location) {
    return (
      <div className="h-[300px] flex items-center justify-center bg-gray-100 rounded">
        <div className="flex items-center text-gray-500">
          <MapPin className="h-5 w-5 mr-2" />
          Loading map...
        </div>
      </div>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: location.latitude,
        lng: location.longitude
      }}
      zoom={15}
      options={{
        disableDefaultUI: false,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true
      }}
    >
      <Marker
        position={{
          lat: location.latitude,
          lng: location.longitude
        }}
        title="Your Location"
      />
    </GoogleMap>
  );
});

const AddTimeSheet = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [status, setStatus] = useState('pending');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [timesheet, setTimesheet] = useState(null);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [signature, setSignature] = useState(null);
  const [storeManager, setStoreManager] = useState('');
  const [breakTime, setBreakTime] = useState(0);
  const [lunchTime, setLunchTime] = useState(0);

  // Fetch stores on component mount
  useEffect(() => {
    const storesRef = ref(db, 'stores');
    const fetchStores = async () => {
      try {
        const snapshot = await get(storesRef);
        if (snapshot.exists()) {
          const storesData = snapshot.val();
          const storeOptions = Object.entries(storesData).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            channel: store.channel,
            parish: store.parish,
            location: store.searchLocation,
            company: store.company
          }));
          setStores(storeOptions);
        }
      } catch (error) {
        setError('Error loading stores: ' + error.message);
      }
    };
    fetchStores();
  }, []);

  // Get current location
  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
      }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          setCurrentLocation(location);
          resolve(location);
        },
        (error) => {
          reject(new Error('Error getting location: ' + error.message));
        }
      );
    });
  };

  useEffect(() => {
    getCurrentLocation().catch(error => setError(error.message));
  }, []);

  const getCurrentTimeString = () => {
    return new Date().toLocaleTimeString();
  };

  const getCurrentDateString = () => {
    return new Date().toISOString().split('T')[0];
  };

  const saveSignatureToStorage = async (signaturePad, timesheetId) => {
    if (!signaturePad || !timesheetId || !auth.currentUser) {
      throw new Error('Missing signature, timesheet ID, or user authentication');
    }

    try {
      // Convert signature to data URL and remove the data URL prefix
      const dataUrl = signaturePad.toDataURL('image/png', 1.0);
      const signatureData = dataUrl.split(',')[1];

      // Calculate approximate size (base64 string length * 0.75 gives rough byte size)
      const approximateSize = signatureData.length * 0.75;
      if (approximateSize > 5 * 1024 * 1024) { // 5MB limit
        throw new Error('Signature size too large. Please try again with a simpler signature.');
      }

      // Create a reference to the signature in Firebase Storage
      // Using userId/timesheetId as the path to match storage rules
      const signaturePath = `signatures/${auth.currentUser.uid}/${timesheetId}`;
      const signatureRef = storageRef(storage, signaturePath);

      // Upload the signature
      await uploadString(signatureRef, signatureData, 'base64', {
        contentType: 'image/png'
      });

      // Get the download URL
      const downloadURL = await getDownloadURL(signatureRef);
      return downloadURL;
    } catch (error) {
      console.error('Error saving signature:', error);
      throw new Error(`Failed to save signature: ${error.message}`);
    }
  };

  const handleCheckIn = async () => {
    if (!selectedStore) {
      setError('Please select a store first');
      return;
    }

    setLoading(true);
    try {
      const location = await getCurrentLocation();
      const checkInTime = Date.now();
      const timesheetRef = push(ref(db, 'timesheets'));
      
      const newTimesheet = {
        userId: auth.currentUser.uid,
        storeId: selectedStore.value,
        storeName: selectedStore.label,
        storeChannel: selectedStore.channel,
        storeParish: selectedStore.parish,
        storeLocation: selectedStore.location,
        checkInTime: checkInTime,
        gpsLatitude: location.latitude,
        gpsLongitude: location.longitude,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        status: 'active',
        records: [{
          status: 'Check In',
          time: getCurrentTimeString(),
          date: getCurrentDateString(),
          gpsLocation: location
        }]
      };

      await set(timesheetRef, newTimesheet);
      setTimesheet({ id: timesheetRef.key, ...newTimesheet });
      setStatus('checkedIn');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    if (!timesheet) return;

    setLoading(true);
    try {
      const location = await getCurrentLocation();
      const timesheetRef = ref(db, `timesheets/${timesheet.id}`);
      const currentTime = Date.now();
      
      const statusMap = {
        startBreak: { status: 'Start Break', newState: 'onBreak', timeField: 'breakStartTime' },
        stopBreak: { status: 'Stop Break', newState: 'checkedIn', timeField: 'breakEndTime' },
        startLunch: { status: 'Start Lunch', newState: 'onLunch', timeField: 'lunchStartTime' },
        stopLunch: { status: 'Stop Lunch', newState: 'checkedIn', timeField: 'lunchEndTime' }
      };

      const statusInfo = statusMap[newStatus];
      const newRecord = {
        status: statusInfo.status,
        time: getCurrentTimeString(),
        date: getCurrentDateString(),
        gpsLocation: location
      };

      const updatedRecords = [...timesheet.records, newRecord];

      if (newStatus === 'stopBreak') {
        const breakStartTime = timesheet.breakStartTime || 0;
        const breakDuration = (currentTime - breakStartTime) / 60000;
        setBreakTime(breakDuration);
      } else if (newStatus === 'stopLunch') {
        const lunchStartTime = timesheet.lunchStartTime || 0;
        const lunchDuration = (currentTime - lunchStartTime) / 60000;
        setLunchTime(lunchDuration);
      }

      const updates = {
        ...timesheet,
        records: updatedRecords,
        status: statusInfo.newState,
        [statusInfo.timeField]: currentTime,
        updatedAt: serverTimestamp()
      };

      await set(timesheetRef, updates);
      setTimesheet(updates);
      setStatus(statusInfo.newState);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckOut = async () => {
    if (!timesheet) return;
    setShowSignaturePad(true);
  };

  const handleCompleteCheckOut = async () => {
    if (!timesheet || !signature || !storeManager) {
      setError('Please provide both signature and store manager name');
      return;
    }

    if (!auth.currentUser) {
      setError('User authentication required');
      return;
    }

    setLoading(true);
    try {
      const location = await getCurrentLocation();
      const timesheetRef = ref(db, `timesheets/${timesheet.id}`);
      const checkOutTime = Date.now();
      
      // Save signature to Storage and get URL
      const signatureUrl = await saveSignatureToStorage(signature, timesheet.id);
      
      const totalMinutes = Math.floor((checkOutTime - timesheet.checkInTime) / 60000);

      const updates = {
        ...timesheet,
        checkOutTime,
        totalTime: totalMinutes,
        breakTime,
        lunchTime,
        signatureUrl,
        storeManagerName: storeManager,
        status: 'completed',
        records: [
          ...timesheet.records,
          {
            status: 'Check Out',
            time: getCurrentTimeString(),
            date: getCurrentDateString(),
            gpsLocation: location
          }
        ],
        updatedAt: serverTimestamp()
      };

      await set(timesheetRef, updates);
      setTimesheet(updates);
      setStatus('checkedOut');
      setShowSignaturePad(false);
    } catch (error) {
      setError('Error completing checkout: ' + error.message);
      console.error('Checkout error:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderProgressSteps = () => {
    const steps = [
      { status: 'checkedIn', label: 'Checked In', done: status !== 'pending' },
      { status: 'onBreak', label: 'Break', done: breakTime > 0 },
      { status: 'onLunch', label: 'Lunch', done: lunchTime > 0 },
      { status: 'checkedOut', label: 'Checked Out', done: status === 'checkedOut' }
    ];

    return (
      <div className="flex justify-between mb-6">
        {steps.map((step, index) => (
          <div key={step.status} className="flex flex-col items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step.done ? 'bg-green-500' : 'bg-gray-300'
            }`}>
              <span className="text-white text-sm">{index + 1}</span>
            </div>
            <span className="text-xs mt-1">{step.label}</span>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Map Section */}
      <div className="mb-6">
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Your Location</h2>
          <LocationMap location={currentLocation} />
        </div>
      </div>

      <h1 className="text-3xl font-bold mb-6">Time Sheet Entry</h1>

      {/* Progress Steps */}
      {status !== 'pending' && renderProgressSteps()}

      {error && (
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700">{error}</p>
          </div>
        </div>
      )}

      {status === 'pending' && (
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Select Store</h2>
          <Select
            options={stores}
            value={selectedStore}
            onChange={setSelectedStore}
            placeholder="Select a store..."
            className="mb-4"
          />
          {selectedStore && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div className="flex items-center space-x-2">
                <Clock className="w-5 h-5 text-gray-400" />
                <span>{selectedStore.channel}</span>
              </div>
              <div className="flex items-center space-x-2">
                <MapPin className="w-5 w-5 text-gray-400" />
                <span>{selectedStore.parish}</span>
              </div>
              <div className="flex items-center space-x-2">
                <Timer className="w-5 h-5 text-gray-400" />
                <span>{new Date().toLocaleTimeString()}</span>
              </div>
            </div>
          )}
          <button
            onClick={handleCheckIn}
            disabled={!selectedStore || loading}
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Clock className="mr-2 h-4 w-4" />
            Check In
          </button>
        </div>
      )}

      {/* Active Session UI */}
      {(status === 'checkedIn' || status === 'onBreak' || status === 'onLunch') && (
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Current Status</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="bg-gray-50 p-4 rounded">
              <p className="text-sm text-gray-600">Store</p>
              <p className="font-medium">{selectedStore?.label}</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p className="text-sm text-gray-600">Check-in Time</p>
              <p className="font-medium">
                {new Date(timesheet?.checkInTime).toLocaleTimeString()}
              </p>
            </div>

            <div className="bg-gray-50 p-4 rounded md:col-span-2">
              <p className="text-sm text-gray-600">Current Status</p>
              <p className="font-medium">
                {status === 'checkedIn' ? 'Working' : 
                 status === 'onBreak' ? 'On Break' : 
                 status === 'onLunch' ? 'On Lunch' : 'Unknown'}
              </p>
            </div>

            {breakTime > 0 && (
              <div className="bg-gray-50 p-4 rounded">
                <p className="text-sm text-gray-600">Break Duration</p>
                <p className="font-medium">{breakTime.toFixed(1)} minutes</p>
              </div>
            )}
            
            {lunchTime > 0 && (
              <div className="bg-gray-50 p-4 rounded">
                <p className="text-sm text-gray-600">Lunch Duration</p>
                <p className="font-medium">{lunchTime.toFixed(1)} minutes</p>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {status === 'checkedIn' && !breakTime && (
              <button
                onClick={() => handleStatusUpdate('startBreak')}
                className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
              >
                <Timer className="mr-2 h-4 w-4" />
                Start Break
              </button>
            )}
            {status === 'onBreak' && (
              <button
                onClick={() => handleStatusUpdate('stopBreak')}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              >
                End Break
              </button>
            )}

            {status === 'checkedIn' && breakTime > 0 && !lunchTime && (
              <button
                onClick={() => handleStatusUpdate('startLunch')}
                className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
              >
                <Timer className="mr-2 h-4 w-4" />
                Start Lunch
              </button>
            )}
            
            {status === 'onLunch' && (
              <button
                onClick={() => handleStatusUpdate('stopLunch')}
                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
              >
                End Lunch
              </button>
            )}

            {status === 'checkedIn' && breakTime > 0 && lunchTime > 0 && (
              <button
                onClick={handleCheckOut}
                className="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
              >
                Check Out
              </button>
            )}
          </div>
        </div>
      )}

      {/* Signature Modal */}
      {showSignaturePad && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-lg">
            <h3 className="text-lg font-semibold mb-4">Store Manager Sign-off</h3>
            
            <input
              type="text"
              placeholder="Store Manager Name"
              value={storeManager}
              onChange={(e) => setStoreManager(e.target.value)}
              className="w-full p-2 border rounded mb-4"
            />
            
            <div className="border rounded mb-4">
              <SignaturePad
                ref={(ref) => setSignature(ref)}
                canvasProps={{
                  className: 'w-full h-64'
                }}
              />
            </div>
            
            <div className="flex justify-end gap-4">
              <button
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50"
                onClick={() => {
                  if (signature) {
                    signature.clear();
                  }
                  setStoreManager('');
                }}
              >
                Clear
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                onClick={handleCompleteCheckOut}
                disabled={!signature?.toData()?.length || !storeManager}
              >
                Complete Check-out
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Checkout Complete View */}
      {status === 'checkedOut' && (
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Check-out Complete</h2>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-gray-50 p-4 rounded">
                <p className="text-sm text-gray-600">Total Time</p>
                <p className="font-medium">
                  {Math.floor(timesheet?.totalTime / 60)} hours{' '}
                  {timesheet?.totalTime % 60} minutes
                </p>
              </div>
              <div className="bg-gray-50 p-4 rounded">
                <p className="text-sm text-gray-600">Break Duration</p>
                <p className="font-medium">{breakTime.toFixed(1)} minutes</p>
              </div>
              <div className="bg-gray-50 p-4 rounded">
                <p className="text-sm text-gray-600">Lunch Duration</p>
                <p className="font-medium">{lunchTime.toFixed(1)} minutes</p>
              </div>
            </div>
            
            <div className="bg-gray-50 p-4 rounded">
              <p className="text-sm text-gray-600">Store Manager</p>
              <p className="font-medium">{storeManager}</p>
            </div>

            <button
              onClick={() => {
                setStatus('pending');
                setTimesheet(null);
                setSelectedStore(null);
                setSignature(null);
                setStoreManager('');
                setBreakTime(0);
                setLunchTime(0);
              }}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
            >
              Start New Timesheet
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTimeSheet;