import React, { useState, useEffect } from 'react';
import { ref, push, get, serverTimestamp } from 'firebase/database';
import { auth, db } from '../firebase';
import Select from 'react-select';
import { AlertCircle, Package } from 'lucide-react';

const UnitType = {
  UNITS: "Units",
  CASES: "Cases"
};

const AddStockCount = () => {
  // State Management
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Form State
  const [stockOnShelf, setStockOnShelf] = useState('');
  const [stockOnShelfUnit, setStockOnShelfUnit] = useState(UnitType.UNITS);
  const [stocksPacked, setStocksPacked] = useState('');
  const [stocksPackedUnit, setStocksPackedUnit] = useState(UnitType.UNITS);
  const [stocksInStore, setStocksInStore] = useState('');
  const [stocksInStoreUnit, setStocksInStoreUnit] = useState(UnitType.UNITS);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesRef = ref(db, 'stores');
        const categoriesRef = ref(db, 'categories');
        const productsRef = ref(db, 'products');

        const [storesSnap, categoriesSnap, productsSnap] = await Promise.all([
          get(storesRef),
          get(categoriesRef),
          get(productsRef)
        ]);

        if (storesSnap.exists()) {
          const storesData = Object.entries(storesSnap.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            location: store.searchLocation,
            parish: store.parish,
            channel: store.channel
          }));
          setStores(storesData);
        }

        if (categoriesSnap.exists()) {
          const categoriesData = Object.entries(categoriesSnap.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        if (productsSnap.exists()) {
          const productsData = Object.entries(productsSnap.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            sku: product.productNumber,
            competitorProductName: product.competitorProductName
          }));
          setProducts(productsData);
        }
      } catch (error) {
        setError('Error loading data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter products when category is selected
  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(
        product => product.categoryId === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
  }, [selectedCategory, products]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedStore || !selectedCategory || !selectedProduct) {
      setError('Please select store, category, and product');
      return;
    }

    if (!stockOnShelf || !stocksPacked || !stocksInStore) {
      setError('Please fill in all stock quantities');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const stockRef = ref(db, 'stock');
      const newStock = {
        storeId: selectedStore.value,
        categoryId: selectedCategory.value,
        productId: selectedProduct.value,
        sku: selectedProduct.sku,
        stockOnShelf: parseFloat(stockOnShelf),
        stockOnShelfUnit: stockOnShelfUnit,
        stocksPacked: parseFloat(stocksPacked),
        stocksPackedUnit: stocksPackedUnit,
        stocksInStore: parseFloat(stocksInStore),
        stocksInStoreUnit: stocksInStoreUnit,
        timestamp: serverTimestamp(),
        uploadedBy: auth.currentUser.uid
      };

      await push(stockRef, newStock);
      
      // Reset form
      setStockOnShelf('');
      setStocksPacked('');
      setStocksInStore('');
      setSelectedProduct(null);
      setSelectedCategory(null);
      setSuccess('Stock count saved successfully');
      
      // Clear success message after 3 seconds
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      setError('Error saving stock count: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const UnitToggle = ({ value, onChange, disabled }) => (
    <div className="flex rounded-md shadow-sm">
      <button
        type="button"
        className={`px-4 py-2 text-sm rounded-l-md ${
          value === UnitType.UNITS
            ? 'bg-blue-500 text-white'
            : 'bg-gray-100 text-gray-700'
        } ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        onClick={() => onChange(UnitType.UNITS)}
        disabled={disabled}
      >
        Units
      </button>
      <button
        type="button"
        className={`px-4 py-2 text-sm rounded-r-md ${
          value === UnitType.CASES
            ? 'bg-blue-500 text-white'
            : 'bg-gray-100 text-gray-700'
        } ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'}`}
        onClick={() => onChange(UnitType.CASES)}
        disabled={disabled}
      >
        Cases
      </button>
    </div>
  );

  if (loading && !selectedStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Add Stock Count</h1>

        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
              <p className="text-green-700">{success}</p>
            </div>
          )}

          <div className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store
              </label>
              <Select
                options={stores}
                value={selectedStore}
                onChange={setSelectedStore}
                placeholder="Select store..."
                isDisabled={loading}
              />
            </div>

            {selectedStore && (
              <div className="bg-gray-50 rounded p-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-medium">Location:</span> {selectedStore.location}
                  </div>
                  <div>
                    <span className="font-medium">Parish:</span> {selectedStore.parish}
                  </div>
                  <div>
                    <span className="font-medium">Channel:</span> {selectedStore.channel}
                  </div>
                </div>
              </div>
            )}

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category
              </label>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={setSelectedCategory}
                placeholder="Select category..."
                isDisabled={loading}
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product
              </label>
              <Select
                options={filteredProducts}
                value={selectedProduct}
                onChange={setSelectedProduct}
                placeholder="Select product..."
                isDisabled={!selectedCategory || loading}
                formatOptionLabel={({ label, competitorProductName }) => (
                  <div>
                    <div>{label}</div>
                    {competitorProductName && (
                      <div className="text-sm text-gray-500">
                        {competitorProductName}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>

            {/* Stock Inputs */}
            <div className="space-y-4">
              {/* Stock on Shelf */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Stock on Shelf
                </label>
                <div className="flex space-x-4">
                  <input
                    type="number"
                    value={stockOnShelf}
                    onChange={(e) => setStockOnShelf(e.target.value)}
                    className="flex-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter quantity"
                    disabled={loading}
                    min="0"
                    step="0.1"
                  />
                  <UnitToggle
                    value={stockOnShelfUnit}
                    onChange={setStockOnShelfUnit}
                    disabled={loading}
                  />
                </div>
              </div>

              {/* Stocks Packed */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Stocks Packed
                </label>
                <div className="flex space-x-4">
                  <input
                    type="number"
                    value={stocksPacked}
                    onChange={(e) => setStocksPacked(e.target.value)}
                    className="flex-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter quantity"
                    disabled={loading}
                    min="0"
                    step="0.1"
                  />
                  <UnitToggle
                    value={stocksPackedUnit}
                    onChange={setStocksPackedUnit}
                    disabled={loading}
                  />
                </div>
              </div>

              {/* Stocks in Store */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Stocks in Store
                </label>
                <div className="flex space-x-4">
                  <input
                    type="number"
                    value={stocksInStore}
                    onChange={(e) => setStocksInStore(e.target.value)}
                    className="flex-1 shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Enter quantity"
                    disabled={loading}
                    min="0"
                    step="0.1"
                  />
                  <UnitToggle
                    value={stocksInStoreUnit}
                    onChange={setStocksInStoreUnit}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Package className="mr-2 h-4 w-4" />
              {loading ? 'Saving...' : 'Save Stock Count'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStockCount;