import React, { useState, useEffect } from 'react';
import { ref as dbRef, push, get } from 'firebase/database'; 
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../firebase';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { 
  AlertCircle,
  Save,
  Image as ImageIcon
} from 'lucide-react';

const ACTION_OPTIONS = [
  { value: 'Dispose', label: 'Dispose' },
  { value: 'Return to Warehouse', label: 'Return to Warehouse' },
  { value: 'Donate', label: 'Donate' }
];

const STATUS_OPTIONS = [
  { value: 'Damaged', label: 'Damaged' },
  { value: 'Expired', label: 'Expired' }
];

const StatusBadge = ({ status }) => {
  const statusClasses = {
    'Damaged': "bg-red-100 text-red-800",
    'Expired': "bg-yellow-100 text-yellow-800"
  };

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-medium ${statusClasses[status]}`}>
      {status}
    </span>
  );
};

const ActionBadge = ({ action }) => {
  const actionClasses = {
    'Dispose': "bg-red-100 text-red-800",
    'Return to Warehouse': "bg-blue-100 text-blue-800",
    'Donate': "bg-green-100 text-green-800"
  };

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-medium ${actionClasses[action]}`}>
      {action}
    </span>
  );
};

const AddProductExpiryDamage = () => {
    // Form state
    const [stores, setStores] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    
    // Selected values
    const [selectedStore, setSelectedStore] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    
    // Form values
    const [amountUnits, setAmountUnits] = useState('');
    const [amountCases, setAmountCases] = useState('');
    const [batchNumber, setBatchNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [photo, setPhoto] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    
    // UI state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    // Helper function for form validation
    const validateForm = () => {
      if (!selectedStore || !selectedCategory || !selectedProduct || !selectedStatus || !selectedAction) {
        setError('Please fill in all required fields');
        return false;
      }
  
      if (!amountUnits && !amountCases) {
        setError('Please enter either units or cases amount');
        return false;
      }
  
      if (!batchNumber) {
        setError('Please enter the batch number');
        return false;
      }
  
      if (!expiryDate) {
        setError('Please select the expiry date');
        return false;
      }
  
      if (!photo) {
        setError('Please upload a photo');
        return false;
      }
  
      return true;
    };
  
    // Handle photo selection
    const handlePhotoChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 5 * 1024 * 1024) { // 5MB limit
          setError('Photo size must be less than 5MB');
          return;
        }
  
        if (!file.type.startsWith('image/')) {
          setError('File must be an image');
          return;
        }
  
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhoto(file);
          setPhotoPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesRef = dbRef(db, 'stores');
        const categoriesRef = dbRef(db, 'categories');
        const productsRef = dbRef(db, 'products');

        const [storesSnap, categoriesSnap, productsSnap] = await Promise.all([
          get(storesRef),
          get(categoriesRef),
          get(productsRef)
        ]);

        if (storesSnap.exists()) {
          const storesData = Object.entries(storesSnap.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            location: store.searchLocation,
            parish: store.parish,
            channel: store.channel
          }));
          setStores(storesData);
        }

        if (categoriesSnap.exists()) {
          const categoriesData = Object.entries(categoriesSnap.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        if (productsSnap.exists()) {
          const productsData = Object.entries(productsSnap.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            sku: product.productNumber
          }));
          setProducts(productsData);
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Error loading data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter products when category is selected
  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(
        product => product.categoryId === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
  }, [selectedCategory, products]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError('');

    try {
      // Upload photo to Firebase Storage
      const userId = auth.currentUser.uid;
      const photoRef = storageRef(
        storage,
        `product_expiry_images/${userId}/${Date.now()}_${photo.name}`
      );
      
      const photoSnapshot = await uploadBytes(photoRef, photo);
      const photoUrl = await getDownloadURL(photoSnapshot.ref);

      // Create database entry
      const productExpiryRef = dbRef(db, 'productExpiry');
      const currentTimestamp = Math.floor(Date.now() / 1000); // Unix timestamp in seconds
      
      const newEntry = {
        storeId: selectedStore.value,
        categoryId: selectedCategory.value,
        productId: selectedProduct.value,
        status: selectedStatus.value,
        action: selectedAction.value,
        amountUnits: parseInt(amountUnits) || 0,
        amountCases: parseInt(amountCases) || 0,
        batchNumber,
        expiryDate: expiryDate ? expiryDate.getTime() / 1000 : null, // Convert to Unix timestamp in seconds
        photoUrl,
        userId: auth.currentUser.uid,
        timestamp: currentTimestamp
      };

      await push(productExpiryRef, newEntry);

      // Reset form
      setSelectedStore(null);
      setSelectedCategory(null);
      setSelectedProduct(null);
      setSelectedStatus(null);
      setSelectedAction(null);
      setAmountUnits('');
      setAmountCases('');
      setBatchNumber('');
      setExpiryDate(null);
      setPhoto(null);
      setPhotoPreview(null);

      setSuccess('Product expiry/damage report saved successfully');
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Error saving report:', error);
      setError('Error saving report: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !selectedStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Add Product Expiry/Damage Report</h1>

        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
              <p className="text-green-700">{success}</p>
            </div>
          )}

          <div className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store *
              </label>
              <Select
                value={selectedStore}
                onChange={setSelectedStore}
                options={stores}
                placeholder="Select store..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Store Details */}
            {selectedStore && (
              <div className="bg-gray-50 rounded p-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-medium">Location:</span> {selectedStore.location}
                  </div>
                  <div>
                    <span className="font-medium">Parish:</span> {selectedStore.parish}
                  </div>
                  <div>
                    <span className="font-medium">Channel:</span> {selectedStore.channel}
                  </div>
                </div>
              </div>
            )}

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category *
              </label>
              <Select
                value={selectedCategory}
                onChange={setSelectedCategory}
                options={categories}
                placeholder="Select category..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product *
              </label>
              <Select
                value={selectedProduct}
                onChange={setSelectedProduct}
                options={filteredProducts}
                placeholder="Select product..."
                isDisabled={!selectedCategory || loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            {/* Status Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Status *
              </label>
              <Select
                value={selectedStatus}
                onChange={setSelectedStatus}
                options={STATUS_OPTIONS}
                placeholder="Select status..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              {selectedStatus && (
                <div className="mt-2">
                  <StatusBadge status={selectedStatus.value} />
                </div>
              )}
            </div>

            {/* Action Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Action *
              </label>
              <Select
                value={selectedAction}
                onChange={setSelectedAction}
                options={ACTION_OPTIONS}
                placeholder="Select action..."
                isDisabled={loading}
                className="react-select-container"
                classNamePrefix="react-select"
              />
              {selectedAction && (
                <div className="mt-2">
                  <ActionBadge action={selectedAction.value} />
                </div>
              )}
            </div>

            {/* Amount Fields */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Units
                </label>
                <input
                  type="number"
                  min="0"
                  value={amountUnits}
                  onChange={(e) => setAmountUnits(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter units"
                  disabled={loading}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Cases
                </label>
                <input
                  type="number"
                  min="0"
                  value={amountCases}
                  onChange={(e) => setAmountCases(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter cases"
                  disabled={loading}
                />
              </div>
            </div>

            {/* Batch Number */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Batch Number *
              </label>
              <input
                type="text"
                value={batchNumber}
                onChange={(e) => setBatchNumber(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Enter batch number"
                disabled={loading}
              />
            </div>

            {/* Expiry Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Expiry Date *
              </label>
              <DatePicker
                selected={expiryDate}
                onChange={setExpiryDate}
                dateFormat="MMM d, yyyy"  // Updated date format
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholderText="Select expiry date"
                disabled={loading}
              />
            </div>

            {/* Photo Upload */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Photo *
              </label>
              <div className="space-y-2">
                <div className="flex items-center justify-center w-full">
                  <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-gray-500 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:text-gray-600 hover:border-gray-400">
                    {photoPreview ? (
                      <img
                        src={photoPreview}
                        alt="Preview"
                        className="h-32 w-32 object-cover rounded-lg"
                      />
                    ) : (
                      <>
                        <ImageIcon className="h-12 w-12 mb-2" />
                        <span className="text-sm">Upload photo</span>
                      </>
                    )}
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handlePhotoChange}
                      disabled={loading}
                    />
                  </label>
                </div>
                {photoPreview && (
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={() => {
                        setPhoto(null);
                        setPhotoPreview(null);
                      }}
                      className="text-red-600 hover:text-red-800"
                    >
                      Remove photo
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="mr-2 h-4 w-4" />
              {loading ? 'Saving...' : 'Save Report'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProductExpiryDamage;