import React, { useState, useEffect } from 'react';
import { ref, push, get, serverTimestamp } from 'firebase/database';
import { db, auth } from '../firebase';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { AlertCircle, Save } from 'lucide-react';
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";

const TAX_OPTIONS = [
  { value: 0, label: '0% Tax' },
  { value: 15, label: '15% Tax' }
];

const formatDateTime = (date) => {
  if (!date) return 'N/A';
  try {
    return format(date, "MMM d, yyyy h:mma").replace(':00', '');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date Error';
  }
};

const AddPriceAudit = () => {
  // State Management
  const [stores, setStores] = useState([]);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [competitorProductName, setCompetitorProductName] = useState('');
  const [auditDate, setAuditDate] = useState(new Date());
  const [normalPrice, setNormalPrice] = useState('');
  const [competitorPrice, setCompetitorPrice] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [priceDifference, setPriceDifference] = useState(0);
  const [priceStatus, setPriceStatus] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  // New Tax States
  const [selectedStoreTax, setSelectedStoreTax] = useState(TAX_OPTIONS[1]);
  const [selectedCompetitorTax, setSelectedCompetitorTax] = useState(TAX_OPTIONS[1]);
  const [totalStorePrice, setTotalStorePrice] = useState(0);
  const [totalCompetitorPrice, setTotalCompetitorPrice] = useState(0);

  // Calculate total prices with tax
  useEffect(() => {
    const storePrice = parseFloat(normalPrice) || 0;
    const storeTaxRate = selectedStoreTax?.value || 0;
    const calculatedStoreTotal = storePrice + (storePrice * (storeTaxRate / 100));
    setTotalStorePrice(calculatedStoreTotal);

    const competitorBasePrice = parseFloat(competitorPrice) || 0;
    const competitorTaxRate = selectedCompetitorTax?.value || 0;
    const calculatedCompetitorTotal = competitorBasePrice + (competitorBasePrice * (competitorTaxRate / 100));
    setTotalCompetitorPrice(calculatedCompetitorTotal);

  }, [normalPrice, competitorPrice, selectedStoreTax, selectedCompetitorTax]);

  // Calculate price difference based on total prices
  useEffect(() => {
    const difference = totalStorePrice - totalCompetitorPrice;
    
    let status = 'Equal';
    if (difference > 0) status = 'Higher';
    if (difference < 0) status = 'Lower';

    setPriceDifference(difference.toFixed(2));
    setPriceStatus(status);
  }, [totalStorePrice, totalCompetitorPrice]);

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const storesRef = ref(db, 'stores');
        const categoriesRef = ref(db, 'categories');
        const productsRef = ref(db, 'products');
        const currentUserRef = ref(db, `users/${auth.currentUser.uid}`);

        const [storesSnap, categoriesSnap, productsSnap, currentUserSnap] = await Promise.all([
          get(storesRef),
          get(categoriesRef),
          get(productsRef),
          get(currentUserRef)
        ]);

        if (storesSnap.exists()) {
          const storesData = Object.entries(storesSnap.val()).map(([id, store]) => ({
            value: id,
            label: store.storeName,
            location: store.searchLocation,
            parish: store.parish,
            channel: store.channel
          }));
          setStores(storesData);
        }

        if (categoriesSnap.exists()) {
          const categoriesData = Object.entries(categoriesSnap.val()).map(([id, category]) => ({
            value: id,
            label: category.categoryName
          }));
          setCategories(categoriesData);
        }

        if (productsSnap.exists()) {
          const productsData = Object.entries(productsSnap.val()).map(([id, product]) => ({
            value: id,
            label: product.productName,
            categoryId: product.categoryId,
            sku: product.productNumber,
            competitorProductName: product.competitorProductName
          }));
          setProducts(productsData);
        }

        if (currentUserSnap.exists()) {
          setCurrentUser(currentUserSnap.val());
        }
      } catch (error) {
        setError('Error loading data: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter products when category is selected
  useEffect(() => {
    if (selectedCategory) {
      const filtered = products.filter(
        product => product.categoryId === selectedCategory.value
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
    setSelectedProduct(null);
    setCompetitorProductName('');
  }, [selectedCategory, products]);

  // Update competitor product name when product is selected
  useEffect(() => {
    if (selectedProduct) {
      setCompetitorProductName(selectedProduct.competitorProductName || '');
    } else {
      setCompetitorProductName('');
    }
  }, [selectedProduct]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedStore || !selectedCategory || !selectedProduct) {
      setError('Please select store, category, and product');
      return;
    }

    if (!normalPrice || !competitorPrice) {
      setError('Please enter both prices');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const priceAuditRef = ref(db, 'priceAudits');
      const formattedDate = formatDateTime(auditDate);
      
      const newPriceAudit = {
        storeId: selectedStore.value,
        storeName: selectedStore.label,
        storeLocation: selectedStore.location,
        categoryId: selectedCategory.value,
        categoryName: selectedCategory.label,
        productId: selectedProduct.value,
        productName: selectedProduct.label,
        sku: selectedProduct.sku,
        auditDate: formattedDate,
        isoDate: auditDate.toISOString(),
        storePrice: parseFloat(normalPrice),
        competitorPrice: parseFloat(competitorPrice),
        storeTaxRate: selectedStoreTax.value,
        competitorTaxRate: selectedCompetitorTax.value,
        totalStorePrice: parseFloat(totalStorePrice.toFixed(2)),
        totalCompetitorPrice: parseFloat(totalCompetitorPrice.toFixed(2)),
        priceDifference: parseFloat(priceDifference),
        priceStatus,
        notes,
        timestamp: serverTimestamp(),
        userId: auth.currentUser.uid,
        merchandiserName: currentUser?.fullName || 'Unknown User',
        competitorProductName: competitorProductName
      };

      await push(priceAuditRef, newPriceAudit);
      
      // Reset form
      setSelectedStore(null);
      setSelectedCategory(null);
      setSelectedProduct(null);
      setCompetitorProductName('');
      setNormalPrice('');
      setCompetitorPrice('');
      setNotes('');
      setAuditDate(new Date());
      setSelectedStoreTax(TAX_OPTIONS[1]);
      setSelectedCompetitorTax(TAX_OPTIONS[1]);
      setSuccess('Price audit saved successfully');
      
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      setError('Error saving price audit: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !selectedStore) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Add Price Audit</h1>

        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 rounded">
              <div className="flex items-center">
                <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                <p className="text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 rounded">
              <p className="text-green-700">{success}</p>
            </div>
          )}

          <div className="space-y-6">
            {/* Store Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Store *
              </label>
              <Select
                options={stores}
                value={selectedStore}
                onChange={setSelectedStore}
                placeholder="Select store..."
                isDisabled={loading}
              />
            </div>

            {/* Store Details */}
            {selectedStore && (
              <div className="bg-gray-50 rounded p-4">
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <span className="font-medium">Location:</span> {selectedStore.location}
                  </div>
                  <div>
                    <span className="font-medium">Parish:</span> {selectedStore.parish}
                  </div>
                  <div>
                    <span className="font-medium">Channel:</span> {selectedStore.channel}
                  </div>
                </div>
              </div>
            )}

            {/* Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Category *
              </label>
              <Select
                options={categories}
                value={selectedCategory}
                onChange={setSelectedCategory}
                placeholder="Select category..."
                isDisabled={loading}
              />
            </div>

            {/* Product Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Product *
              </label>
              <Select
                options={filteredProducts}
                value={selectedProduct}
                onChange={setSelectedProduct}
                placeholder="Select product..."
                isDisabled={!selectedCategory || loading}
                formatOptionLabel={({ label, sku }) => (
                  <div>
                    <div className="font-medium">{label}</div>
                    <div className="text-xs text-gray-400">
                      SKU: {sku}
                    </div>
                  </div>
                )}
              />
            </div>

            {/* Competitor Product Name Display */}
            {selectedProduct && (
              <div className="bg-gray-50 rounded p-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Competitor Product
                </label>
                <input
                  type="text"
                  value={competitorProductName}
                  onChange={(e) => setCompetitorProductName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter competitor product name..."
                />
              </div>
            )}

            {/* Audit Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Audit Date *
              </label>
              <DatePicker
                selected={auditDate}
                onChange={(date) => setAuditDate(date)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MMM d, yyyy h:mma"
                disabled={loading}
              />
            </div>

            {/* Price and Tax Inputs */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Our Price Section */}
              <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700">
                  Our Price Details
                </label>
                <div className="space-y-2">
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">
                      Base Price *
                    </label>
                    <div className="relative">
                      <span className="absolute left-3 top-2 text-gray-500">$</span>
                      <input
                        type="number"
                        value={normalPrice}
                        onChange={(e) => setNormalPrice(e.target.value)}
                        step="0.01"
                        min="0"
                        className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder="0.00"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">
                      Tax Rate *
                    </label>
                    <Select
                      options={TAX_OPTIONS}
                      value={selectedStoreTax}
                      onChange={setSelectedStoreTax}
                      placeholder="Select tax rate..."
                      isDisabled={loading}
                    />
                  </div>
                  {normalPrice && (
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-sm text-gray-600">Tax Amount: ${((parseFloat(normalPrice) * selectedStoreTax.value) / 100).toFixed(2)}</div>
                      <div className="font-medium">Total: ${totalStorePrice.toFixed(2)}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Competitor Price Section */}
              <div className="space-y-4">
                <label className="block text-sm font-medium text-gray-700">
                  Competitor Price Details
                </label>
                <div className="space-y-2">
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">
                      Base Price *
                    </label>
                    <div className="relative">
                      <span className="absolute left-3 top-2 text-gray-500">$</span>
                      <input
                        type="number"
                        value={competitorPrice}
                        onChange={(e) => setCompetitorPrice(e.target.value)}
                        step="0.01"
                        min="0"
                        className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder="0.00"
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm text-gray-600 mb-1">
                      Tax Rate *
                    </label>
                    <Select
                      options={TAX_OPTIONS}
                      value={selectedCompetitorTax}
                      onChange={setSelectedCompetitorTax}
                      placeholder="Select tax rate..."
                      isDisabled={loading}
                    />
                  </div>
                  {competitorPrice && (
                    <div className="bg-gray-50 p-2 rounded">
                      <div className="text-sm text-gray-600">Tax Amount: ${((parseFloat(competitorPrice) * selectedCompetitorTax.value) / 100).toFixed(2)}</div>
                      <div className="font-medium">Total: ${totalCompetitorPrice.toFixed(2)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Price Difference Display */}
            {(normalPrice || competitorPrice) && (
              <div className={`p-4 rounded-md ${
                priceStatus === 'Higher' ? 'bg-red-50 text-red-700' :
                priceStatus === 'Lower' ? 'bg-green-50 text-green-700' :
                'bg-gray-50 text-gray-700'
              }`}>
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-lg font-medium">Price Difference (Including Tax)</h3>
                    <p className="text-sm mt-1">
                      Our price is {priceStatus.toLowerCase()} than competitor's price
                    </p>
                  </div>
                  <div className="text-2xl font-bold">
                    ${Math.abs(priceDifference)}
                  </div>
                </div>
              </div>
            )}

            {/* Notes */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Notes
              </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Add any additional notes here..."
                disabled={loading}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={loading}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="mr-2 h-4 w-4" />
              {loading ? 'Saving...' : 'Save Price Audit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPriceAudit;